<template>
  <div id="app">
    <loader :show="show" />
    <v-container fluid class="text-center">
      <v-row justify="start">
        <v-col cols="12" xs="12" sm="12" md="12" lg="12">
          <strong> <h1>Participación de encuesta con Referencia I</h1></strong>
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid class="text-center" v-if="primera">
      <v-row justify="start">
        <v-col cols="12" xs="12" sm="12" md="12" lg="12">
          <strong>
            Preguntas sección I.- Acontecimiento traumático severo</strong
          >
        </v-col>
      </v-row>
      <v-card>
        <v-data-table
          :headers="headers"
          :items="seccionI"
          class="elevation-1"
          hide-default-footer
        >
          <template v-slot:no-data>
            <v-alert :value="true" color="#ffffff" icon="warning">
              <p style="color: black">No hay datos en sistema ...</p>
            </v-alert>
          </template>
          <template v-slot:body="{ items }">
            <tbody>
              <tr
                v-for="item in items"
                :key="item.Id"
                :class="{ selectedRow: item === selectedItem }"
              >
                <td class="text-sm-center">
                  {{ item.consecutivoGuiaPregunta }}
                </td>
                <td class="text-sm-center">{{ item.pregunta }}</td>
                <td class="text-sm-center">
                  <template>
                    <v-radio-group v-model="item.respuesta" row>
                      <v-radio label="Sí" value="true" color="green"></v-radio>
                      <v-radio label="No" value="false" color="red"></v-radio>
                    </v-radio-group>
                  </template>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-card>
      <v-row>
        <v-col cols="12" xs="12" sm="12" md="12" lg="12">
          <button class="botonAmarillo mt-5" @click="validacion()">
            Siguiente
          </button>
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid class="text-center" v-if="segunda">
      <v-row justify="start">
        <v-col cols="12" xs="12" sm="12" md="12" lg="12">
          <strong>
            Preguntas sección II.- Recuerdos persistentes sobre el
            acontecimiento (durante el último mes)</strong
          >
        </v-col>
      </v-row>
      <v-card>
        <v-data-table
          :headers="headers"
          :items="seccionII"
          class="elevation-1"
          hide-default-footer
        >
          <template v-slot:no-data>
            <v-alert :value="true" color="#ffffff" icon="warning">
              <p style="color: black">No hay datos en sistema ...</p>
            </v-alert>
          </template>
          <template v-slot:body="{ items }">
            <tbody>
              <tr
                v-for="item in items"
                :key="item.Id"
                :class="{ selectedRow: item === selectedItem }"
              >
                <td class="text-sm-center">
                  {{ item.consecutivoGuiaPregunta }}
                </td>
                <td class="text-sm-center">{{ item.pregunta }}</td>
                <td class="text-sm-center">
                  <template class="text-sm-center">
                    <v-radio-group v-model="item.respuesta" row>
                      <v-radio label="Sí" value="true" color="green"></v-radio>
                      <v-radio label="No" value="false" color="red"></v-radio>
                    </v-radio-group>
                  </template>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-card>
      <v-row>
        <v-col cols="12" xs="12" sm="12" md="12" lg="12">
          <button class="botonAmarillo mt-5" @click="validacion2()">
            Siguiente
          </button>
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid class="text-center" v-if="tercera">
      <v-row justify="start">
        <v-col cols="12" xs="12" sm="12" md="12" lg="12">
          <strong>
            Preguntas sección III.- Esfuerzo por evitar circunstancias parecidas
            o asociadas al acontecimiento (durante el último mes)</strong
          >
        </v-col>
      </v-row>
      <v-card>
        <v-data-table
          :headers="headers"
          :items="seccionIII"
          class="elevation-1"
          hide-default-footer
        >
          <template v-slot:no-data>
            <v-alert :value="true" color="#ffffff" icon="warning">
              <p style="color: black">No hay datos en sistema ...</p>
            </v-alert>
          </template>
          <template v-slot:body="{ items }">
            <tbody>
              <tr
                v-for="item in items"
                :key="item.Id"
                :class="{ selectedRow: item === selectedItem }"
              >
                <td class="text-sm-center">
                  {{ item.consecutivoGuiaPregunta }}
                </td>
                <td class="text-sm-center">{{ item.pregunta }}</td>
                <td class="text-sm-center">
                  <template>
                    <v-radio-group v-model="item.respuesta" row>
                      <v-radio label="Sí" value="true" color="green"></v-radio>
                      <v-radio label="No" value="false" color="red"></v-radio>
                    </v-radio-group>
                  </template>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-card>
      <v-row>
        <v-col cols="12" xs="12" sm="12" md="12" lg="12">
          <button class="botonAmarillo mt-5" @click="validacion3()">
            Siguiente
          </button>
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid class="text-center" v-if="cuarta">
      <v-row justify="start">
        <v-col cols="12" xs="12" sm="12" md="12" lg="12">
          <strong>
            Preguntas sección IV.- Afectación (durante el último mes)</strong
          >
        </v-col>
      </v-row>
      <v-card>
        <v-data-table
          :headers="headers"
          :items="seccionIV"
          class="elevation-1"
          hide-default-footer
        >
          <template v-slot:no-data>
            <v-alert :value="true" color="#ffffff" icon="warning">
              <p style="color: black">No hay datos en sistema ...</p>
            </v-alert>
          </template>
          <template v-slot:body="{ items }">
            <tbody>
              <tr
                v-for="item in items"
                :key="item.Id"
                :class="{ selectedRow: item === selectedItem }"
              >
                <td class="text-sm-center">
                  {{ item.consecutivoGuiaPregunta }}
                </td>
                <td class="text-sm-center">{{ item.pregunta }}</td>
                <td class="text-sm-center">
                  <template>
                    <v-radio-group v-model="item.respuesta" row>
                      <v-radio label="Sí" value="true" color="green"></v-radio>
                      <v-radio label="No" value="false" color="red"></v-radio>
                    </v-radio-group>
                  </template>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-card>
      <v-row>
        <v-col cols="12" xs="12" sm="12" md="12" lg="12">
          <button class="botonAmarillo mt-5" @click="validacion4()">
            Guardar
          </button>
        </v-col>
      </v-row>
    </v-container>

    <!--Error-->
    <v-dialog v-model="error" width="400">
      <v-card>
        <v-card-title class="red white--text">
          <span class="headline">Error</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="center">{{ respuesta }}</h3>
          <br />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" text @click="error = false">Corregir</v-btn>
        </v-card-actions>
      </v-card> </v-dialog
    ><!--Confirmacion-->
    <v-dialog v-model="confirmation" width="400">
      <v-card>
        <v-card-title class="green white--text">
          <span class="headline">Confirmación</span>
        </v-card-title>
        <v-card-text class="text-center">
          <br />
          <h2>¡Felicidades ha terminado la encuesta!</h2>
          <br />
          <h3 class="text-justify">
            Agradecemos tu participación en la encuesta con referencia I.
          </h3>
          <br />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" text @click="confirmation = false">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Instrucciones-->
    <v-dialog v-model="instrucciones" width="400" scrollable>
      <v-card>
        <v-card-title class="success white--text">
          <span class="headline">Instrucciones</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-md-justify text-sm-justify text-lg-justify">
            Agrademos tu participación en la encuesta, a continuación aparecerán
            5 valores diferentes para cada respuesta donde la carita
            alegre(Verde) indica que Estas de acuerdo o siempre realizas la
            actividad y la carita enojada(roja) responde que nunca realizas la
            actividad o que estas totalmente desacuerdo. ¡No esperes más
            comienza!
          </h3>
          <br />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            text
            @click="
              instrucciones = false;
              primera = true;
            "
            >Empezar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
// Comentario prueba
import Loader from "@/components/Loader";
export default {
  components: {
    Loader,
  },
  data() {
    return {
      show: false,
      selectedItem: null,
      headers: [
        {
          text: "Id",
          align: "center",
          sortable: false,
          value: "id",
        },
        {
          text: "Pregunta",
          align: "center",
          sortable: false,
          value: "pregunta",
        },
        {
          text: "Sí / No",
          align: "center",
          sortable: false,
          value: "respuesta",
        },
      ],
      seccionI: [],
      seccionII: [],
      seccionIII: [],
      seccionIV: [],
      error: false,
      respuesta: "",
      confirmation: false,
      instrucciones: true,
      primera: false,
      segunda: false,
      tercera: false,
      cuarta: false,
      questions: [],
      dataTable: 0,
      secciones: [],
      idEncuesta: this.$route.query.idCuestionario,
      idPreguntaIncompleta: 0,
      server2: this.$route.query.server,
    };
  },
  methods: {
    listar() {
      this.seccionI = [];
      axios
        .get(this.server2 + "/climaLaboralPreguntas/guia/" + 1)
        .then((response) => {
          var auxSeccion = response.data;
          var temp = [];
          auxSeccion.forEach((value, index) => {
            if (value.seccion != "") {
              temp.push(value.seccion);
            }
          });
          const myObj = {};
          this.secciones = [];
          temp.forEach((el) => {
            // comprobamos si el valor existe en el objeto
            if (!(el in myObj)) {
              // si no existe creamos ese valor y lo añadimos al array final, y si sí existe no lo añadimos
              myObj[el] = true;
              this.secciones.push(el);
            }
          });
          var seccio = auxSeccion.forEach((value, index) => {
            if (value.seccion === this.secciones[0]) {
              this.seccionI.push({
                id: value.id,
                consecutivoGuiaPregunta: value.consecutivoGuiaPregunta,
                pregunta: value.pregunta,
                respuesta: null,
              });
            }
          });
        })
        .catch((e) => {
          console.log(e);
        });
    },
    seccion2() {
      this.seccionII = [];
      axios
        .get(this.server2 + "/climaLaboralPreguntas/guia/" + 1)
        .then((response) => {
          var auxSeccion = response.data;
          var temp = [];
          auxSeccion.forEach((value, index) => {
            if (value.seccion != "") {
              temp.push(value.seccion);
            }
          });
          const myObj = {};
          this.secciones = [];
          temp.forEach((el) => {
            // comprobamos si el valor existe en el objeto
            if (!(el in myObj)) {
              // si no existe creamos ese valor y lo añadimos al array final, y si sí existe no lo añadimos
              myObj[el] = true;
              this.secciones.push(el);
            }
          });
          var seccio = auxSeccion.forEach((value, index) => {
            if (value.seccion === this.secciones[1]) {
              this.seccionII.push({
                id: value.id,
                consecutivoGuiaPregunta: value.consecutivoGuiaPregunta,
                pregunta: value.pregunta,
                respuesta: null,
              });
            }
          });
          var tamSecc = this.secciones.length;
          /*console.log("tamaño de secciones");
          console.log(tamSecc);*/
          this.dataTable = tamSecc;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    seccion3() {
      this.seccionIII = [];
      axios
        .get(this.server2 + "/climaLaboralPreguntas/guia/" + 1)
        .then((response) => {
          var auxSeccion = response.data;
          var temp = [];
          auxSeccion.forEach((value, index) => {
            if (value.seccion != "") {
              temp.push(value.seccion);
            }
          });
          const myObj = {};
          this.secciones = [];
          temp.forEach((el) => {
            // comprobamos si el valor existe en el objeto
            if (!(el in myObj)) {
              // si no existe creamos ese valor y lo añadimos al array final, y si sí existe no lo añadimos
              myObj[el] = true;
              this.secciones.push(el);
            }
          });
          var seccio = auxSeccion.forEach((value, index) => {
            if (value.seccion === this.secciones[2]) {
              this.seccionIII.push({
                id: value.id,
                consecutivoGuiaPregunta: value.consecutivoGuiaPregunta,
                pregunta: value.pregunta,
                respuesta: null,
              });
            }
          });
          var tamSecc = this.secciones.length;
          /*console.log("tamaño de secciones");
          console.log(tamSecc);*/
          this.dataTable = tamSecc;
        })
        .catch((e) => {
          console.log(e);
        });
    },

    seccion4() {
      this.seccionIV = [];
      axios
        .get(this.server2 + "/climaLaboralPreguntas/guia/" + 1)
        .then((response) => {
          var auxSeccion = response.data;
          var temp = [];
          auxSeccion.forEach((value, index) => {
            if (value.seccion != "") {
              temp.push(value.seccion);
            }
          });
          const myObj = {};
          this.secciones = [];
          temp.forEach((el) => {
            // comprobamos si el valor existe en el objeto
            if (!(el in myObj)) {
              // si no existe creamos ese valor y lo añadimos al array final, y si sí existe no lo añadimos
              myObj[el] = true;
              this.secciones.push(el);
            }
          });
          var seccio = auxSeccion.forEach((value, index) => {
            if (value.seccion === this.secciones[3]) {
              this.seccionIV.push({
                id: value.id,
                consecutivoGuiaPregunta: value.consecutivoGuiaPregunta,
                pregunta: value.pregunta,
                respuesta: null,
              });
            }
          });
          var tamSecc = this.secciones.length;
          /*console.log("tamaño de secciones");
          console.log(tamSecc);*/
          this.dataTable = tamSecc;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    validacion() {
      //console.log(this.seccionI);
      var aux = this.seccionI.filter((pregunta) => pregunta.respuesta === null);
      if (aux.length == 0) {
        var auxiliar = this.seccionI.filter(
          (pregunta) => pregunta.respuesta === "true"
        );
        if (auxiliar.length == 0) {
          //console.log("todas  no, acaba");
          this.primera = false;
          this.idPreguntaIncompleta = this.seccionI[
            this.seccionI.length - 1
          ].consecutivoGuiaPregunta;
          this.guardar();
        } else {
          //console.log("alguna fue si, continua");
          this.primera = false;
          this.seccion2();
          this.segunda = true;
        }
      } else {
        this.respuesta = "Es necesario contestar todas las preguntas";
        this.error = true;
      }
    },
    validacion2() {
      //console.log(this.seccionII);
      var aux = this.seccionII.filter(
        (pregunta) => pregunta.respuesta === null
      );
      if (aux.length == 0) {
        var auxiliar = this.seccionII.filter(
          (pregunta) => pregunta.respuesta === "true"
        );
        this.segunda = false;
        this.seccion3();
        this.tercera = true;
      } else {
        this.respuesta = "Es necesario contestar todas las preguntas";
        this.error = true;
      }
    },
    validacion3() {
      //console.log(this.seccionIII);
      var aux = this.seccionIII.filter(
        (pregunta) => pregunta.respuesta === null
      );
      if (aux.length == 0) {
        var auxiliar = this.seccionIII.filter(
          (pregunta) => pregunta.respuesta === "true"
        );
        this.tercera = false;
        this.seccion4();
        this.cuarta = true;
      } else {
        this.respuesta = "Es necesario contestar todas las preguntas";
        this.error = true;
      }
    },
    validacion4() {
      //console.log(this.seccionIV);
      var aux = this.seccionIV.filter(
        (pregunta) => pregunta.respuesta === null
      );
      if (aux.length == 0) {
        var auxiliar = this.seccionIV.filter(
          (pregunta) => pregunta.respuesta === "true"
        );
        //console.log("se finaliza");
        this.cuarta = false;
        this.guardar();
      } else {
        this.respuesta = "Es necesario contestar todas las preguntas";
        this.error = true;
      }
    },
    guardar() {
      this.questions = this.seccionI.concat(
        this.seccionII.concat(this.seccionIII.concat(this.seccionIV))
      );
      this.questions.forEach((element, index) => {
        delete element.consecutivoGuiaPregunta;
        delete element.pregunta;
      });
      var allQuestions = [];
      //se prepara el arreglo para enviarlo como el servicio lo solicita
      this.questions.forEach((element, index) => {
        var pregunta = new Object();
        pregunta.IdPregunta = element.id;
        pregunta.Respuesta = element.respuesta == "true" ? true : false;
        allQuestions.push(pregunta);
      });
      /*console.log("Elementos finales", allQuestions);
      console.log(
        "UrlFinal",
        this.server2 + "/climaLaboralResultados/guardar-resultados-encuesta"
      );
      console.log(this.idEncuesta);
      console.log("idIncompleto: ", this.idPreguntaIncompleta);
      */
      axios
        .post(
          this.server2 + "/climaLaboralResultados/guardar-resultados-encuesta",
          {
            IdEncuesta: this.idEncuesta,
            Preguntas: allQuestions,
            CuestionarioIncompletoPregId: this.idPreguntaIncompleta,
          }
        )
        .then((response) => {
          //console.log(response);
          this.primera = false;
          this.confirmation = true;
        })
        .catch((e) => {
          console.log(e);
          this.respuesta = "No fue posible guardar su respuesta";
          this.error = true;
        });
    },
  },
  created() {
    this.listar();
  },
};
</script>
